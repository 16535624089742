import React from 'react'

export const Sector = ({ sector , gestion, index}) => {
    return (
            <> 
            <tr>
                    <td> { sector  && sector} </td>                     
                    <td> { gestion  && gestion } </td>                     
            </tr>
        </>
    )
}
