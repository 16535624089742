import React from 'react'
import { useDispatch } from 'react-redux';
import { activeCategoria, startLoadingGestiones } from '../../actions/gestion';

export const Categorias = ({categorias, index}) => {
        
    const dispatch = useDispatch();

    
    const handleOption = (e)  => {
        console.log(categorias);
        dispatch(activeCategoria( categorias ))
        dispatch(startLoadingGestiones());
    }    

    return (
        <button 
            onClick={handleOption}
            className="list-group-item bg-info text-white">
            {categorias.categoria}
        </button>
    )
}
