import { axiosConToken } from "../helpers/axios";
import { types } from "../types/types";

//PRODUCTOS DE UNA GESTIÓN
export const gestionSetActive = (id, codigo) => {

    return async( dispatch ) => {  
        if (codigo) {
            dispatch(activeGestion(codigo));

        } else {
            dispatch(activeGestion(id));
        }
        try {

            if (codigo) {
                const resp = await axiosConToken(`inventario-v2/productos/${codigo}`)
                dispatch(getProductosGestionExito(resp.data.productos))
            } else {
                const resp = await axiosConToken(`productos/gestion/${id}`)
                dispatch(getProductosGestionExito(resp.data.productos))
            }
           
        } catch (error) {
            console.log(error.response);
        }
    }
}

export const activeGestion = (id) => ({
    type: types.gestionSetActive,
    payload: id
})

export const getProductosGestionExito = (productos) => ({
    type: types.getProductosGestion,
    payload: productos
})

export const startLoadingProducto = () => ({
    type: types.startLoadingProducto,
})


// BUSCAR CODIGO DE PRODUCTO GESTION /productos/dashboard/gestion/:gestionID/buscar/:termino
export const startLoadingProductosSearch = (termino, gestionID) => {
    return async(dispatch, getState ) => {  
        dispatch(getStartLoadingGestionesSearch());
        await setTimeout( async () => {
            try {
                const resp = await axiosConToken(`productos/dashboard/gestion/${gestionID}/buscar/${termino}`)
                dispatch(getGestiones(resp.data.productos))

            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    }
}

const getStartLoadingGestionesSearch = () => ({
    type: types.startLoadingProductoSearch
})

const getGestiones = (gestiones) => ({ 
    type: types.getProductosGestion,
    payload: gestiones
});


// SI NO BUSCA NADA
export const startLoading = (id) => {
    return async(dispatch ) => {  
        dispatch(getStartLoadingSearch());
        dispatch(activeGestion(id));
        try {
            const resp = await axiosConToken(`productos/gestion/${id}`)
            dispatch(getProductosGestionExito(resp.data.productos))
        } catch (error) {
            console.log(error.response);
        }
    } 
}

const getStartLoadingSearch = () => ({
    type: types.startLoading
})

