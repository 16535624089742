import React from 'react'

export const ProductosGestionList = ({ codigo, cantidad, escaneado , sector, index}) => {



    var cantidadF = 0;
    var usuario = '';
    // var d;
    if ( escaneado.length >= 0 ) {
        
        escaneado.map( e => (
                cantidadF   += parseInt(e.cantidad),
                usuario     = e.usuarioEmail
                // img         = e.img
                // d = ( e.descripcion === undefined ) ? '-' : e.descripcion
            ));
        }
      
    return (
        <> 
            <tr>
                <th scope="row">{index+1}</th>
                <td className="text-center">{codigo}</td>
                
                <td className="text-center"> { sector ? sector 
                                : escaneado.map(e => (
                                    <p key={e._id} className="text-info"> {e.sector} </p>
                                ))  
                            }
                </td> 

                <td className="text-center"> { cantidad } </td>
                <td className="text-center"> { (escaneado.length > 0 && escaneado.length <= 1 ) ? cantidadF 
                                                                        :  escaneado.map(e => (
                                                                            <p key={e._id} className="text-info"> {e.cantidad} </p>
                                                                        ))
                     } 
                </td>

                <td className="text-center">{ (escaneado.length > 0) ? cantidadF - cantidad : '-' }</td>

                <td className="text-center">{ (escaneado.length > 0) ? ( cantidadF >= cantidad) 
                                                        ? ( cantidadF === cantidad)
                                                            ? (<p className="text-success">COMPLETADO</p>) 
                                                                : ( <p className="text-info"> SOBREPASADA </p>)
                                                                    : ( <p className="text-danger">INCOMPLETO</p>) 
                                                                        : <p className="text-dark">NO ESCANEADO</p> } </td>
                
                <td className="text-center" >{ (escaneado.length > 0 && escaneado.length <= 1 ) ? usuario 
                                                                        :  escaneado.map(e => (
                                                                            <p key={e._id} className="text-dark"> {e.usuarioEmail} </p>
                                                                        )) 
                
                    } 
                </td>

                {/* <td className="text-center">
                    {
                        (img) ? <img width="100px" height="100px" className="img-responsive" alt="Bodega" src={process.env.REACT_APP_API_URL+'/upload/imagen/productos/'+img}/>
                                               : <p>No-image</p> 
                    }
                </td> */}

               

            </tr>
        </>
    )
}
