import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'

//redux Persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { rootReducer } from '../reducers/rootReducer';

const persistConfig = {
    key: 'root',
    storage,
    blacklist:['auth', 'producto']
}

//redux persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

//Herramienta redux
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);

export const persistor = persistStore(store);



