import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { StartFileuploadInventario, StartFileuploadGuia } from '../../actions/gestion';
import Swal from 'sweetalert2';

export const Fileupload = () => {
    
    const dispatch = useDispatch();
    
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('seleccionar un archivo ...');

    //Saber donde subir
    const tipos      = useSelector( state => state.gestion.tipos );
    const tipoActivo = useSelector( state => state.gestion.tipoActivo );


    const onChange = e => {       
        //validar si hay arrepentimiento en el dato:
        if (e.target.files.length === 0) return;
        setFile(e.target.files[0])
        setFilename(e.target.files[0].name)
    }
    
    const onSubmit = async e => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('archivo', file);
        
        //No ejecutar nada si no esta cargando el archiVo       
        if (file.length === 0) {
            return Swal.fire('Error', 'Debe subir un archivo (excel estandarizado)', 'error');            
        }

        //Validar tipo
        const tipo = tipos.filter(tipo => tipo._id === tipoActivo);

        if (tipo[0].gestion === 'INVENTARIO') {
            dispatch(StartFileuploadInventario(formData));
        } else {
            dispatch(StartFileuploadGuia(formData))
        }
        //Dispatch
        
        


    }
    

    return (
        <>  
            <div className="container bg-dark pt-5 pb-5">
                <form 
                    onSubmit={onSubmit}
                >
                    <div className="custom-file">
                        <input type="file" 
                               className="custom-file-input" 
                               id="customFile"
                               onChange={onChange}
                        />
                        <label className="custom-file-label" 
                                htmlFor="customFile">{filename}</label>
                    </div>
        
                    <input type="submit" 
                           value="Subir Guia" 
                           className="btn btn-primary btn-block mt-4"/>
                </form>
            </div>        

        </>
    )
}
