import React from 'react'
import './loading.css';

export const Loading = () => {
    return (
        <>
            <div className="loader"> </div>
            <div className="text-center blink_me"> Espere hasta que termine de cargar...</div>
        </>
    )
}
