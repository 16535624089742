import React from 'react'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { seleccionarGestionActualizar, eliminarGestion, actualizarGestionEstado, eliminarGestionV2} from '../../actions/gestion';
import { gestionSetActive, startLoadingProducto } from '../../actions/productos';

export const GestionesListDashboard = ( {_id, codigo, descripcion, fecha, estado, index } ) => {

    const dispatch = useDispatch();
    const history  = useHistory();
    
    const update = () => {
        dispatch(startLoadingProducto());
        dispatch(seleccionarGestionActualizar(_id));  
        dispatch(gestionSetActive(_id));
        setTimeout(() => {
            history.push(`/gestiones/editar/${_id}`)
        }, 500);
    }

    const ver = () => {
        if (codigo>0) {
            dispatch(gestionSetActive('',codigo));
            dispatch(startLoadingProducto());
            setTimeout(() => {
                history.push(`/gestiones/${_id}`)
            }, 100);
        } else {
            dispatch(gestionSetActive(_id));
            dispatch(startLoadingProducto());
            setTimeout(() => {
                history.push(`/gestiones/${_id}`)
            }, 100);
        }
    }

    const handleCambiarEstado = () => {
        dispatch(actualizarGestionEstado(_id))
    }

    const handleEliminar = () => {
        dispatch(eliminarGestion(_id))
        if (codigo>0) {
            dispatch(eliminarGestionV2(codigo));
        } 
    }

    return (
        
            <tr>
                <th scope="row">{index+1}</th>
                <td className="text-uppercase">{descripcion}</td>
                <td className="text-uppercase">{moment(fecha).format('l')}</td>
                <td className="text-uppercase">{ estado ? (<span className=" mb-1 bg-primary  rounded border border-primary text-white">COMPLETADO</span>) 
                                                        : (<span className=" mb-1 bg-secondary rounded border border-secondary text-white">INCOMPLETO</span>) }</td>
                <td>
                <button 
                    onClick={update}
                    type="button"
                    className="btn btn-primary mr-2"
                > Editar </button>
                <button 
                    onClick={ver}
                    type="button"
                    className="btn btn-success mr-2"
                > Ver </button>
                <button 
                    onClick={handleCambiarEstado}
                    type="button"
                    className="btn btn-info mr-2"
                > CAMBIAR ESTADO </button>
                <button 
                    onClick={handleEliminar}
                    type="button"
                    className="btn btn-danger"
                > Eliminar </button> 
                
                </td>
            </tr>
    
    )
}
