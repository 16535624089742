import React, {useEffect} from 'react'
import { GestionesScreen } from './gestion/GestionesScreen'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTipoGestion } from '../hooks/useTipoGestion'

import { useForm } from '../hooks/useForm';

import { startLoadingGestiones, startLoadingGestionesSearch } from '../actions/gestion'
import { Loading } from './ui/Loading'

export const DashboardScreen = () => {
    
    const dispatch = useDispatch();

    //Categoria activa
    const categoriasActiva = useSelector( state => state.gestion.categoriaActiva );

    //opciones 
    const tipos      = useSelector( state => state.gestion.tipos );
    const tipoActivo = useSelector( state => state.gestion.tipoActivo );
    const loading    = useSelector(state => state.gestion.loading)    

    const [formValue, handleChangeSearch] = useForm({
        search:''
      })
    
    //Hook para seleccionar tipo
    const [tipo, SelectTipoGestion] = useTipoGestion('', tipoActivo, tipos);
    
    useEffect(() => {
        dispatch(startLoadingGestiones());
    }, [dispatch, tipo, tipos])

    const {search} = formValue;

    const handleSearch = e => {
      e.preventDefault();
      dispatch(startLoadingGestionesSearch(search))
    }
    
    
    if (!tipos || !categoriasActiva || loading ) {
        return (<div className="center-screen-loading"> <Loading /> </div>);
    }
    

    return (
        <>
            <div className="jumbotron jumbotron-fluid">
              <div className="container">
                <p className="lead">Seleccione una categorias desde la barra de navegación.</p>
                <h1 className="display-4"> CATEGORIA: <span className="text-info"> {categoriasActiva.categoria} </span> </h1>
                <hr/>
                <form className="form-inline col-6 my-lg-0 space-between">
                    <input className="form-control mr-sm-2" 
                           type="search" 
                           placeholder="Buscar gestion" 
                           aria-label="Search" 
                           name="search"
                           value={search}
                           onChange={handleChangeSearch}
                           
                    />
                    <button 
                            onClick={handleSearch}
                            className="btn btn-outline-success my-2 my-sm-0" 
                            type="submit"
                            > Search
                    </button>
                </form>
              </div>
            </div>
 
            <div className="container text-center">
                <h4 className="text-uppercase"> Seleccionar tipo de gestión </h4>
                <SelectTipoGestion />
            </div>

            <GestionesScreen />

            <div className="text-center">
                <div row="row">
                    <div className="col-12">
                        <Link 
                            to="/gestiones/nuevo"
                            type="button"
                            className="btn btn-success mr-2"
                        > CREAR GESTION </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
