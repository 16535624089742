import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { GestionTableList } from './GestionTableList';
import { Loading } from '../ui/Loading';

export const GestionScreen = ({history}) => {
    
    const { gestionID } = useParams();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.producto.loading);
   
    
    useEffect(() => {
        // dispatch( gestionSetActive(gestionID) )   
    }, [dispatch, gestionID, loading])

   
    
    //RETURN BUTTON
    const handleReturn = () => {
        if (history.length <= 2) {
            history.push('/')
        } else {
            history.goBack();
        }
    }

    return (
        <>
            { !loading 

                        ?
                        <>
                         <button 
                                className="btn btn-outline-danger"
                                onClick={ handleReturn }
                        > Return </button>
                        <p/>
                        
                        
                        <div className="container-fluid">
                            <div className="mt-5">
                                <GestionTableList />
                            </div>
                        </div>
                        </>    
                        :   
                        <Loading />
            }
           
        </>
    )
}
