import React from 'react'
import { useSelector } from 'react-redux'

export const ProgressBar = () => {
    
    const porcentaje = useSelector(state => state.gestion.porcentaje)

    return (
        <div className="progress mt-5 mb-4">
            <div className="progress-bar bg-success" 
                 role="progressbar" 
                 style={{ width: `${porcentaje}%` }}
                 aria-valuenow="25" 
                 aria-valuemin="0" 
                 aria-valuemax="100"> {porcentaje}% </div>
        </div>
    )
}
