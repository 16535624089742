import { types } from '../types/types';
// {
//     id: 'askdjhaksdjas',
//     descripcion: 'Cumpleaños del jefe',
//     fecha: 'Comprar el pastel',
//     tipo: {
//              gestion: 'INVENTARIO'
//         }
//     categoria: {
//              gestion: 'categoria'
//         }
//     usuario: {
//         _id: '123',
//         name: 'Fernando'
//     }
// }


const initialState = {
    gestiones: [],
    gestionActiva: null,
    categorias: null,
    categoriaActiva: null,
    tipos: null,
    tipoActivo:null,
    productosGestion: null,
    porcentaje: null,
    
    columnsFile: null,
    columnsFileSectores: null,

    productosFile:null,
    sectoresFileV2:null,

    loading: true,
    nuevaGestion: null,
    editarGestion: null
}

export const gestionReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        
        case types.getCategorias:
            return {
                ...state,
                categorias: action.payload,
                loading: true
            }

            
        // TIPO GESTION   
        case types.getTiposGestion:
            return {
                ...state,
                tipos: action.payload
            }    
        case types.tipoGestionSetActive:
            return{
                ...state,
                tipoActivo: action.payload
            }

         // CATEGORIA   
        case types.tipoCategoriaSetActive:
            return{
                ...state,
                categoriaActiva: action.payload
            }  



        //GESTIONES GET
        case types.getStartLoadingGestiones:
        case types.getStartLoadingGestionesSearch:
        case types.starLoadingGestiones:
            return {
                ...state,
                loading: true
            }



        // GESTION V1
        case types.getGestiones:
            return{
                ...state,
                gestiones: action.payload,
                loading: false
            }    
        case types.gestionSetActive:
            return {
                ...state,
                gestionActiva: action.payload,
            }   

      
            
        //FILES
        case types.porcentajeSet: 
            return {
                ...state,
                porcentaje: action.payload
            }
        case types.startLoadingFile: 
            return {
                ...state,
                loading: true,
            }   
        case types.getProductosFileInventario:
            return {
                ...state,
                productosFile: action.payload,
                columnsFile: [
                    { dataField:'codigo',     text:"codigo" },
                    { dataField:'cantidad',   text:"cantidad" },
                    { dataField:'modelo',     text:"modelo" },
                    { dataField:'gestión ID', text:"gestion" } 
                ],
                loading: false,
            }
        
        // INVENTARIO V2
        case types.getProductosFileInventarioV2_Sectores:
            return {
                ...state,
                sectoresFileV2: action.payload,
                columnsFileSectores: [
                    { dataField:'ubicación',  text:"sector" },
                    { dataField:'gestión ID', text:"gestion" } 
                ]
            }    

        case types.getProductosFileGuia:
            return {
                ...state,
                productosFile: action.payload,
                columnsFile: [
                    { dataField:'codigo',   text:"codigo" },
                    { dataField:'cantidad', text:"cantidad" }, 
                    { dataField:'sector',   text:"Sector/Modelo/Caja" } 
                ],
                loading: false,

            }
        //CRUD GESTIONES: CREAR
        case types.crearNuevaGestion:
            return{
                ...state,
                loading: true,
                nuevaGestion: action.payload
            }
        case types.creandoInventario:
        case types.creandoGuia:
            return {
                ...state,
                loading: false,
            }    
        //CRUD GESTIONES: ACTUALIZAR
        case types.limpiarActualizarGestion:
            return {
                ...state,
                editarGestion: null
            }
        case types.gestionStartActualizar:
            return {
                ...state,
                editarGestion: action.payload
            }
            
        case types.actualizarGestionEstado:    
        case types.actualizarGestion:
            return {
                ...state
            }
       

        case types.logout:
            return{
                
            }
        default:
            return state;
    }

}


