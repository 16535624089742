
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { activeTipoGestion } from '../actions/gestion';

export const useTipoGestion = (label, stateInicial, opciones) => {
    
    const dispatch = useDispatch();
    const [state, actualizarState] = useState(stateInicial);

    const selectOption = e => {
        actualizarState(e.target.value)
        dispatch(activeTipoGestion(e.target.value))        
    }
    
    const SelectTipoGestion = () => (

        <>
            <label>{label}</label>
            <select
                className="form-control form-control-lg"
                onChange={ selectOption }
                value={state}
            >
                { opciones.map(opcion => (
                    <option key={opcion._id} value={opcion._id }>{opcion.gestion} </option>
                ))}
            </select>
        </>
    );

    //Retornar state, interfaz y f(x) el state
    return [state, SelectTipoGestion, actualizarState];
}
