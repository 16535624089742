import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GestionesListDashboard } from './GestionesListDashboard';
import { Loading } from '../ui/Loading';
import Paginator from 'react-hooks-paginator';


export const GestionesScreen = () => {

    const pageLimit = 10;

    const { gestiones } = useSelector(state => state.gestion)    

    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data] = useState([]);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        setCurrentData(gestiones.slice(offset, offset + pageLimit));
      }, [offset, data, gestiones]);

    if (!gestiones) {
        return <Loading />;
    }    

    return (
        <>
            <div className="container-fluid">
                <div className="mt-5">
                    {
                        gestiones.length > 0

                            ? 
                                (
                                    <>
                                    <table className="table">
                                        <thead className="thead-dark">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Gestion</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">estado</th>
                                            <th scope="col">Acciones</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                            { currentData.map( (gestion, index) => (
                                                <GestionesListDashboard 
                                                    key =  {gestion._id}
                                                    index = {index}
                                                    {...gestion}
                                                />
                                            ))}
                                        </tbody> 
                                    </table>
                                    <Paginator
                                        totalRecords={gestiones.length}
                                        pageLimit={pageLimit}
                                        pageNeighbours={2}
                                        setOffset={setOffset}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                    
                                    </>
                                )  
                            
                            :   ( <p> No hay registros </p> )
                    }
                    
                    
                </div>
                
            </div>
        </>
    )
}
