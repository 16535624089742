import axios from 'axios';

export const clienteAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const axiosSinToken = (endpoint, data, method='GET') => {
    
    

    if (method === 'GET') {
        return clienteAxios.get(`/${endpoint}`);
    } else {
        return clienteAxios(`/${endpoint}`, {
            method,
            headers: {
                'Content-type': 'application/json'
            },
            data: JSON.stringify(data)
        })
    }
}

const axiosConToken = ( endpoint, data, method = 'GET', type ) => {

    const token = localStorage.getItem('token') || '';
    if ( method === 'GET' ) {
        return clienteAxios.get(`/${endpoint}`, {
            method,
            headers: {
                'x-token': token
            }
        });
    } 
    else {        
        return clienteAxios(`/${endpoint}`, {
            method : method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            data: JSON.stringify(data)        
        });
    }
}


export {
    axiosSinToken,
    axiosConToken
}