import React from 'react'
import { useDispatch } from 'react-redux';


//Custom hooks
import { useForm } from '../../hooks/useForm';

//Actions
import { startLogin } from '../../actions/auth';
import './login.css';

export const LoginScreen = () => {

    const dispatch = useDispatch();
    
    const [formLoginValues, handleLoginInputChange] = useForm({
        // email:"cristian.avilesm@utem.cl",
        // password:"admin_role"
    });

    const { email, password } = formLoginValues;

    console.log(process.env.REACT_APP_API_URL);


    //Submit Form
    const handleLogin = ( e ) => {
        e.preventDefault();        
        dispatch( startLogin( email, password ) );
    }
  

    return (
        <div className="container login-container">
            <div className="row">
                <div className="col-md-6 login-form-1">
                    <h3 className="text-uppercase">identificarse</h3>
                    <form
                        onSubmit={handleLogin}
                    >
                        <div className="form-group">
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Correo"
                                name="email"
                                value={email}
                                onChange={handleLoginInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Contraseña"
                                name="password"
                                value={password}
                                onChange={handleLoginInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="submit"
                                className="btnSubmit"
                                value="Login" 
                            />
                        </div>
                    </form>
                </div>
                <div className="col-md-6 login-form-2">
                    <h3>NICHIMAR DE COMERCIO LTDA.</h3>
                    <p className="text-white">Sistema para el control de inventario y bodegaje Nichimar. 
                        Para solicitar una key, debe contactar a soporte nichimar.</p>
                </div>
            </div>
        </div>        
    )
}
