import { types } from '../types/types';

const initialState = {
    productos: null,
    loading: true,
    loadingSearch: false
}

export const productosReducer = ( state = initialState, action ) => {

    switch ( action.type ) {
        case types.startLoadingProducto:
            return {
                ...state,
                loading: true,
            }
        case types.startLoading:
        case types.startLoadingProductoSearch:
            return {
                ...state,
                loadingSearch: true
            }    
        case types.getProductosGestion:
            return {
                ...state,
                productos: action.payload,
                loading: false,
                loadingSearch: false
            }

        default:
            return state;
    }

}


