import React from 'react'
import { Provider } from 'react-redux';
import { store } from './store/store';

//REdux persist
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './store/store'

import { AppRouter } from './router/AppRouter'

export const BodegaApp = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppRouter/>
            </PersistGate>
        </Provider>
    )
}
