import React from 'react'

export const Producto = ({ codigo , cantidad, modelo, sector, gestion, index}) => {
    return (
            <> 
            <tr>
                    <td>{codigo}</td>
                    <td> { cantidad } </td>
                    <td> { sector   ? sector : modelo  ? modelo : "NO APLICA"} </td>                     
                    <td> { gestion  && gestion } </td>                     
            </tr>
        </>
    )
}
