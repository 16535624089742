import React, { useEffect } from 'react'

import { useForm } from '../../hooks/useForm';
import { useTipoGestion } from '../../hooks/useTipoGestion'
import { useSelector, useDispatch } from 'react-redux';

import { getProductosFileInventario, 
    getProductosFileGuia, 
    crearGestion, 
    crearGestionInventario,
    crearGestionGuia,
    crearGestionInventarioV2 } from '../../actions/gestion';
import { FileProductosList } from '../file/FileProductosList';
import { Loading } from '../ui/Loading';
import { ProgressBar } from '../ui/ProgressBar'
import { Fileupload } from '../file/Fileupload';
import Swal from 'sweetalert2';
import { FileSectoresList } from '../file/FileSectoresList';
    

const gestion = ({
    descripcion:'',
    categoria:'',
    tipo:'' ,
    codigo:'',
    id:''        
})

const gestionV2 = ({
    descripcion:'',
    categoria:'',
    tipo:'' ,
    codigo:'',
    id:''        
})

export const CreateGestionScreen = ({history}) => {
    
    const dispatch = useDispatch();


    //State form
    const [formValues, handleInputChange] = useForm({
        descripcion:''
    });


    //Categoria activa
    const categoriaActiva = useSelector( state => state.gestion.categoriaActiva );
    //opciones
    const tipos           = useSelector( state => state.gestion.tipos );
    const tipoActivo      = useSelector( state => state.gestion.tipoActivo );
      

    //Hook
    const [tipo, SelectTipoGestion] = useTipoGestion('', tipoActivo, tipos);
    //Creacon
    const loading       = useSelector( state => state.gestion.loading );
    const productos     = useSelector( state => state.gestion.productosFile)
    const sectoresFile  = useSelector( state => state.gestion.sectoresFileV2)



    //Cargar datos
    useEffect(() => {
        const getFile = () => {
            //Validar tipo
            const tipo = tipos.filter(tipo => tipo._id === tipoActivo);
            gestion.tipo = tipo[0].gestion;
            if (tipo[0].gestion === 'INVENTARIO') {
                return dispatch(getProductosFileInventario())
            } 
            else if(tipo[0].gestion === 'EMBARQUE') {
                return dispatch( getProductosFileGuia())
            }          
        }
        getFile();

    }, [dispatch, tipo, tipos, tipoActivo])
    

    if (!productos) {
        return null
    }


    //RETURN BUTTON
    const handleReturn = () => {
        if (history.length <= 2) {
            history.push('/')
        } else {
            history.goBack();
        }
    }

    // AGREGAR GESTIÓN
    const { descripcion } = formValues;

    
    //Metodo para agregar
    const handleSubmit = async(e) => {
        
        e.preventDefault();
        //Validar 
        if (descripcion.trim() === '') {
            return Swal.fire('Error', 'Describir una gestión es obligatorio', 'error');
        }  

        //Inicializar Inventario V1 y embarque
        const tipo          = tipos.filter(tipo => tipo._id === tipoActivo);
        gestion.descripcion = descripcion;
        gestion.categoria   = categoriaActiva._id;
        gestion.tipo        = tipoActivo;
        gestion.codigo      = productos[0].gestion;

        //Inicializar v2
        gestionV2.descripcion = descripcion;
        gestionV2.categoria   = 'REPUESTOS';
        gestionV2.tipo        = 'inventario';
        gestionV2.codigo      = productos[0].gestion;
        
        //Crear dependiendo inventario o embarque
        if (tipo[0].gestion === "INVENTARIO") {

            console.log('entro a inventario ');

            const resp = await dispatch(crearGestionInventarioV2(gestionV2))

            // Gestión Inventario V1 - ( Versión Temporal )
            if (resp) {
                const id   = await dispatch(crearGestion(gestion))        
                gestion.id = id;
                
                setTimeout(async () => {
                    if ( gestion.id) {
                        await dispatch(crearGestionInventario(gestion))
                        //Redireccionar
                        history.push('/');
                    }
                }, 500);
            } 
            
            
           
        } else {  
            // Crear gestión
            const id   = await dispatch(crearGestion(gestion))        
            gestion.id = id;
            
            console.log(gestion);

            setTimeout(async () => {
                if ( gestion.id) {
                    await dispatch(crearGestionGuia(gestion))
                    //Redireccionar
                    history.push('/');
                }
            }, 100);
        } 
    }


    return (
        <>
            <div className="row justify-content-center">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

                        <button 
                            className="btn btn-outline-danger"
                            onClick={ handleReturn }
                        > Return </button>
                        <p/>

                        <div className="jumbotron jumbotron-fluid">
                          <div className="container">
                            <p className="lead">Seleccione una categorias desde la barra de navegación.</p>
                            <h1 className="display-4"> CATEGORIA: <span className="text-info"> {categoriaActiva.categoria} </span> </h1>
                          </div>
                        </div>
                        <div className="container text-center mb-5" >
                            <h4 className="text-uppercase"> Seleccionar tipo de gestión </h4>
                            <SelectTipoGestion />
                        </div>   
                        
                        
                        <ProgressBar /> 
                        
                        <Fileupload />

                        { 
                            (loading && !productos) ? '' : 
                            <>
                                <div className="container-fluid mt-5"> <FileProductosList productos = {productos }  /> </div>
                                
                                { 
                                    (gestion.tipo === 'INVENTARIO') && <div className="container-fluid mt-5"> <FileSectoresList sectores = {sectoresFile}  /> </div>
                                }
                            </>    
                        }

                        {/* FORMULARIO DE CREACIÓN */}
                        { loading && <Loading />} 

                        <form 
                            onSubmit={handleSubmit}
                        >
                            <div className="form-group">
                                <label> Gestión </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingresar gestión"
                                    name="descripcion"
                                    value={descripcion}
                                    onChange={handleInputChange}
                                />
                            </div>

                            { 
                                // (gestion.tipo === 'INVENTARIO') && 
                                //                                 <div className="form-group">
                                //                                     <label> Código único para Gestión </label>
                                //                                     <small> ( Debe ser el mismo que en el archivo excel ) </small>
                                //                                     <input
                                //                                         type="text"
                                //                                         className="form-control"
                                //                                         placeholder="Ingresar gestión"
                                //                                         name="descripcion"
                                //                                     />
                                //                                 </div>
                            
                            }
                            
                            
                            <div className="mb-5 mt-5 text-center">
                                <button
                                    type="submit"
                                    className=" btn
                                                btn-success
                                                font-weight-bold
                                                text-uppercase
                                                btn-lg
                                                "
                                > Guardar Gestión </button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

