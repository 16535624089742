import { axiosSinToken, axiosConToken } from '../helpers/axios';
import { types } from '../types/types';
import Swal from 'sweetalert2';



export const startLogin = ( email, password ) => {

    
    return async( dispatch ) => {
        try {
            const resp = await axiosSinToken( 'auth', { email, password }, 'POST' );
            
            console.log(resp.data);

            if( resp.data.ok ) {
                localStorage.setItem('token', resp.data.token );
                localStorage.setItem('token-init-date', new Date().getTime() );   
                dispatch( login({
                    uid: resp.data.id,
                    nombre: resp.data.nombre
                }))
            }
        } catch (error) {                
            console.log(error.response);
            // Swal.fire('Error', error.response.data.msg, 'error');
        }
    }    
}

export const startRegister = ( email, password, nombre ) => {
    return async( dispatch ) => {
        try {
            const resp = await axiosSinToken( 'auth/nuevo', { email, password, nombre }, 'POST' );            
            if( resp.data.ok ) {
                localStorage.setItem('token', resp.data.token );
                localStorage.setItem('token-init-date', new Date().getTime() );   
                dispatch( login({
                    uid: resp.data.id,
                    name: resp.data.nombre
                }))
                Swal.fire('Guardado', 'Usuario creado con éxito', 'success');
            }
        } catch (error) {
            console.log(error.response);
            if (error.response.status === 404) {
                Swal.fire('Error', 'Contactar al Administrador', 'error');
            } else if (error.response.data.errors) {
                Swal.fire('Error', 'Error al ingresar los datos (verifique la información)', 'error');
            } else {
                Swal.fire('Error', error.response.data.msg, 'error');
            }
          
        }
    }
}

export const startChecking = () => {
    return async(dispatch) => {
        // Si no hay token en local
        if (localStorage.getItem("token") === null)  return dispatch(checkingFinish());
    
        try {

            const resp = await axiosConToken( 'auth/renovar' );
            localStorage.setItem('token', resp.data.token );
                localStorage.setItem('token-init-date', new Date().getTime() );   
                dispatch( login({
                    uid: resp.data.id,
                    nombre: resp.data.nombre
                }))
        } catch (error) {
            console.log(error.response);
            dispatch(checkingFinish());
        }
    }
}

//Si falla la renovación
const checkingFinish = () => ({ 
    type: types.authCheckingFinish 
});



const login = ( user ) => ({
    type: types.authLogin,
    payload: user
});


export const startLogout = () => {
    return ( dispatch ) => {

        localStorage.clear();
        // dispatch( eventLogout() );
        dispatch( logout() );
    }
}

const logout = () => ({ 
    type: types.authLogout 
})