import { axiosConToken, clienteAxios } from '../helpers/axios';
import { types } from '../types/types';
import Swal from 'sweetalert2';


//=================================================================
//  INFORMACIÓN ADICIONAL PRE-CARGAR ( CATEGORIAS - TIPOS GESTIÓN )
//=================================================================
export const startLoadingCategorias = () => {
    return async(dispatch, getState) => {            
        try {
            if (getState().gestion.categoriaActiva) { return ; }
            const resp = await axiosConToken( 'categorias' );
            dispatch(startLoadingListCategorias(resp.data.categorias));  
            dispatch(activeCategoria(resp.data.categorias[0]))         
        } catch (error) {
            console.log(error.response);
        }
    }
}

const startLoadingListCategorias = (categorias) => ({ 
    type: types.getCategorias,
    payload: categorias
});

export const startLoadingTipos = () => {
    return async(dispatch, getState) => {    
        try {
            if (getState().gestion.tipos) { return ; }
            const resp = await axiosConToken( 'tipo/gestion' );
            
            dispatch(startLoadingListTipos(resp.data.gestiones));  
            const id = resp.data.gestiones[0]._id;
            dispatch(activeTipoGestion(id))      
        } catch (error) {
            console.log(error.response);
        }
    }
}

const startLoadingListTipos = (tipos) => ({ 
    type: types.getTiposGestion,
    payload: tipos
});

// ACTIVO TIPO DE GESTIÓN
export const activeTipoGestion = (id) => ({
    type: types.tipoGestionSetActive,
    payload: id
})

// ACTIVO CATEGORIA
export const activeCategoria = (id) => ({
    type: types.tipoCategoriaSetActive,
    payload: id
})


//=================================================================
//  OBTENER GESTIONES 
//=================================================================
export const startLoadingGestiones = () => {
    return async(dispatch, getState ) => {  
        dispatch(starLoadingGestiones());
        await setTimeout( async () => {
            try {
                const { categoriaActiva, tipoActivo } = getState().gestion;            
                const resp = await axiosConToken(`gestiones/${tipoActivo}/categoria/${categoriaActiva._id}`)
                dispatch(getGestiones(resp.data.gestiones))
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    }
}

const starLoadingGestiones = () => ({
    type: types.getStartLoadingGestiones
})

//Buscar gestiones
export const startLoadingGestionesSearch = (termino) => {
    return async(dispatch, getState ) => {  
        dispatch(getStartLoadingGestionesSearch());
        await setTimeout( async () => {
            try {
                const { categoriaActiva, tipoActivo } = getState().gestion;            
                const resp = await axiosConToken(`gestiones/${tipoActivo}/categoria/${categoriaActiva._id}/${termino}`)
                dispatch(getGestiones(resp.data.gestiones))
            } catch (error) {
                console.log(error.response);
            }
        }, 500);
    }
}

const getStartLoadingGestionesSearch = () => ({
    type: types.getStartLoadingGestionesSearch
})

const getGestiones = (gestiones) => ({ 
    type: types.getGestiones,
    payload: gestiones
});





//=================================================================
//  FILES
//=================================================================
export const StartFileuploadInventario = (formData) => {
    return async( dispatch ) => {  
        const token = localStorage.getItem('token') || '';
        dispatch(percentageSet(0));
        try {
            await clienteAxios('/upload', {
                        method : 'PUT',
                        headers: {
                            'Content-type' : 'multipart/form-data',
                            'x-token': token
                        },
                        onUploadProgress: progressEvent => {
                            // Ir agregando el porcentaje
                            dispatch( percentageSet(parseInt( Math.round( (progressEvent.loaded * 100 ) / progressEvent.total ))));
                            //Limpiar  el procentage
                            setTimeout(async () => {
                                await dispatch(percentageSet(0));
                                await dispatch(getProductosFileInventario());
                            }, 3000);
                        },
                        data: formData        
                    });                    
        } catch (error) {
            console.log(error.response);
            if (error.response.data.err) {
                return Swal.fire('Error', error.response.data.err.message, 'error');
            }

        }
        
    }
}

export const StartFileuploadGuia = (formData) => {
    return async( dispatch ) => {  
        const token = localStorage.getItem('token') || '';
        dispatch(percentageSet(0));
        try {
            await clienteAxios('/upload/guia', {
                        method : 'PUT',
                        headers: {
                            'Content-type' : 'multipart/form-data',
                            'x-token': token
                        },
                        onUploadProgress: progressEvent => {
                            // Ir agregando el porcentaje
                            dispatch( percentageSet(parseInt( Math.round( (progressEvent.loaded * 100 ) / progressEvent.total ))));
                            //Limpiar  el procentage
                            setTimeout(async () => {
                                await dispatch(percentageSet(0));
                                await dispatch(getProductosFileGuia());
                            }, 3000);
                        },
                        data: formData        
                    });
        } catch (error) {
            console.log(error.response);
        }
    }
}

export const percentageSet = (porcentaje) => ({
    type: types.porcentajeSet,
    payload: porcentaje
})

//============================
//OBTENER LOS DATOS DE ARCHIVO
//============================
export function getProductosFileInventario() {
    return async (dispatch) => {
        dispatch(startLoadingFile());
        try {
            const resp         = await axiosConToken('productos/excel/inventario');
            
            // Inventario V2
            const respSectores = await axiosConToken('inventario-v2/import-movil/sectores');
                        
            // SECTORES
            dispatch( getProductoFileInventarioV2SectoresExito(respSectores.data) ); 
            // PRODUCTOS
            dispatch( getProductoFileInventarioExito(resp.data) ); 

        } catch (error) {
            console.log(error.response);   
        }
        
    }
}


// getProductosFileInventarioV2_Sectores

export function getProductosFileGuia() {
    return async (dispatch) => {
        //Evaluar
        dispatch(startLoadingFile());
        try {
            const resp = await axiosConToken('sectores/excel/guia');
            // console.log(resp.data); 
            dispatch( getProductoFileGuiaExito(resp.data)); 
        } catch (error) {
            console.log(error);   
        }
        
    }
}


const startLoadingFile = () => ({
    type: types.startLoadingFile
}) 


const getProductoFileInventarioExito = (productos) => ({
    type: types.getProductosFileInventario,
    payload: productos
})

//inventario v2
const getProductoFileInventarioV2SectoresExito = (productos) => ({
    type: types.getProductosFileInventarioV2_Sectores,
    payload: productos
})

const getProductoFileGuiaExito = (productos) => ({
    type: types.getProductosFileGuia,
    payload: productos
})


//=================================================================
//  CRUD GESTIONES : CREAR
//=================================================================


//INVENTARIO V1
export const crearGestion = (gestion) => {
    return async (dispatch) => {
        //Evaluar
        try {
            //crear gestion
            const resp = await axiosConToken('gestiones/nuevo', gestion, 'POST');
            dispatch(crearGestionExito(resp.data.gestion));
            return resp.data.gestion._id;
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

//INVENTARIO V2
export const crearGestionInventarioV2 = (gestion) => {
    return async (dispatch) => {
        //Evaluar
        try {
            //crear gestion
            await axiosConToken('inventario-v2/add', gestion, 'POST');
            await dispatch(crearProductosGestionV2(gestion));
            return true;  //true
    
        } catch (error) {
            console.log(error.response);  
            Swal.fire('Error', error.response.data.msg , 'error'); 
            return false;
        }
    }
}

const crearProductosGestionV2 = (gestion) => {
    return async (dispatch) => {
        //Evaluar
        try {
            //crear productos
            setTimeout(async () => {
                //gestion no se usa como atributo
                await axiosConToken('inventario-v2/productos/'+gestion.codigo , gestion, 'POST');       
            }, 500);
            return true;
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
            return false;
        }
    }
}


export const crearGestionGuia = (gestion) => {
    return async(dispatch) => {
        try {            
                const url = `sectores/file/gestion/${gestion.id}`;
                await axiosConToken(url, gestion, 'POST' );
                dispatch(crearGestionGuiaExito());
                Swal.fire('Guardado','Se ha guardado el registro con éxito', 'success');
            }
        catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

export const crearGestionInventario = (gestion) => {
    return async (dispatch) => {       
      
        //Evaluar        
        try {
            const url = `productos/excel/gestion/${gestion.id}`;
            // crear guía inventario
            await axiosConToken(url, gestion, 'POST' );
            dispatch(crearGestionInventarioExito());
            Swal.fire('Guardado','Se ha guardado el registro con éxito', 'success');
            
        } catch (error) {
            console.log(error.response);
            Swal.fire('Error', error.response.data.msg, 'error');
        }
    }
}


const crearGestionExito = (gestion) => ({
    type: types.crearNuevaGestion,
    payload: gestion 
})

const crearGestionInventarioExito = () => ({
    type: types.creandoInventario,
})

const crearGestionGuiaExito = () => ({
    type: types.creandoGuia,
})


//=================================================================
//  CRUD GESTIONES : ACTUALIZAR
//=================================================================

export const seleccionarGestionActualizar = (id) => {
    return async (dispatch) => {
        //Evaluar
        dispatch(limpiarActualizarGestion());
        try {
            const resp = await axiosConToken(`gestiones/${id}`);
            dispatch(seleccionaraGestionActualizarExito(resp.data.gestion));
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

const limpiarActualizarGestion = () => ({
    type: types.limpiarActualizarGestion,
})
const seleccionaraGestionActualizarExito = (gestion) => ({
    type: types.gestionStartActualizar,
    payload: gestion
})

export const actualizarGestion = (gestion) => {
    return async (dispatch) => {
        //Evaluar
        try {
            //crear gestion
            await axiosConToken(`gestiones/${gestion.id}`, gestion, 'PUT');
            dispatch(actualizarGestionExito());
            Swal.fire('Guardado','Se actualizo el registro con éxito', 'success');
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

const actualizarGestionExito = () => ({
    type: types.actualizarGestion,
})


export const actualizarGestionEstado = (id) => {
    return async (dispatch) => {
        const token = localStorage.getItem('token') || '';

        try {
            //crear gestion
            await clienteAxios(`gestiones/estado/${id}`, {
                method:'PUT',
                headers:{
                  'x-token': token
                }
            })
            // console.log(resp);
            dispatch(actualizarGestionEstadoExito());
            dispatch(startLoadingGestiones());
            Swal.fire('Guardado','Se actualizo el registro con éxito', 'success');
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

const actualizarGestionEstadoExito = () => ({
    type: types.actualizarGestionEstado
})



//=================================================================
//  CRUD GESTIONES : ELIMINAR
//=================================================================
export const eliminarGestion = (id) => {
    return async (dispatch) => {
        //Evaluar
        const token = localStorage.getItem('token') || '';

        try {
            await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then( async (result) => {
                if (result.value) {
                  //delete gestion
                  await clienteAxios(`gestiones/${id}`, {
                      method:'DELETE',
                      headers:{
                        'x-token': token
                      }
                  })


                  dispatch(eliminarGestionExito());
                  dispatch(startLoadingGestiones());
                  Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
                }
              })
            } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

export const eliminarGestionV2 = (codigo) => {
    return async (dispatch) => {
        //Evaluar
        const token = localStorage.getItem('token') || '';
        try {
            await clienteAxios(`inventario-v2/${codigo}`, {
              method:'DELETE',
                headers:{
                  'x-token': token
              }
            });
            dispatch(eliminarGestionExito());   
        } catch (error) {
            console.log(error.response);   
            Swal.fire('Error', error.response.data.msg , 'error');
        }
    }
}

const eliminarGestionExito = () => ({
    type: types.actualizarGestion,
})


export const logoutClean = () => ({
    type: types.logout
})
