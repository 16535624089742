import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ProductosGestionList } from './ProductosGestionList';

import Paginator from 'react-hooks-paginator';
import { CSVLink } from "react-csv";
import { useForm } from '../../hooks/useForm';
import { startLoading, startLoadingProductosSearch } from '../../actions/productos';
import { Loading } from '../ui/Loading';


export const GestionTableList = () => {
    
    const pageLimit = 10;

    const loadingSearch = useSelector( state => state.producto.loadingSearch);

    const productos = useSelector( state => state.producto.productos);
    const gestiones = useSelector( state => state.gestion.gestiones);
    const gestionA  = useSelector( state => state.gestion.gestionActiva);
    

    //PAGINACION
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data] = useState([]);
    const [currentData, setCurrentData] = useState([]);

    //BUSQUEDA
    const dispatch = useDispatch();
    const [formValue, handleChangeSearch] = useForm({
      search:''
    });
    const {search} = formValue;
    
    useEffect(() => {
      setCurrentData(productos.slice(offset, offset + pageLimit));
    }, [offset, data, productos]);

    

    var name = '';
    if (gestiones.length > 0 ) {
      name = gestiones[0].descripcion;
    } else {
      return name = 'data'
    }


    const auxList = [];
    var cantidadF = 0;
    
    productos.map( (p) => {
      if (p.escaneado.length >= 1) {
       
        for (let i = 0; i < p.escaneado.length; i++) {
            cantidadF += p.escaneado[i].cantidad;    
        }

        p.escaneado.map((e) => {
          

          return auxList.push( { 
                          'codigo'       : e.codigo,
                          'modelo'       : p.modelo, 
                          'cantidad'     : p.cantidad,
                          'sector'       : e.sector,
                          'cantidadE'    : e.cantidad,
                          'cantidadFinal': cantidadF,
                          'diferencia'   : cantidadF - p.cantidad,
                          'estado'       : (cantidadF - p.cantidad === 0 ) ? 'Completado' : ( cantidadF - p.cantidad > 0 ) ? 'Sobrepasado' : 'Incompleto',                                                      
                          'usuarioEmail' : e.usuarioEmail,
                          'descripcion'  : e.descripcion
                        });
            
        });
        cantidadF = 0;
      } else {
        return auxList.push({ 
          'codigo'        : p.codigo, 
          'modelo'        : p?.modelo, 
          'cantidad'      : p.cantidad,
          'sector'        : p?.sector,
          'cantidadE'     : '',
          'cantidadFinal' : '',
          'diferencia'    : '',
          'estado'        : 'NO ESCANEADO',                                                     
          'usuarioEmail'  : '',
          'descripcion'   : p?.descripcion
        })
      }
      return null;
    });

    const headers = [
      { label: "Codigo", key: "codigo" },
      { label: "Modelo", key: "modelo" },
      { label: "Caja/Sector", key: "sector" },
      { label: "Cantidad (O)", key: "cantidad" },
      { label: "Cantidad (E)", key: "cantidadE" },
      { label: "Cantidad Final", key: "cantidadFinal" },
      { label: "Diferencia", key: "diferencia" },
      { label: "Estado", key: "estado" },
      { label: "Usuario", key: "usuarioEmail" },
      { label: "Descripcion", key: "descripcion" },
    ];

    
    // METODO BUSQUEDA
    const handleSearch = e => {
      e.preventDefault();
      if (search) {
        dispatch(startLoadingProductosSearch(search, gestionA));
      } else {
        dispatch(startLoading(gestionA));
      }
    }

    const refreshData = e => {
      e.preventDefault();
      dispatch(startLoading(gestionA));      
    }
    


    return (
      (loadingSearch ) ? <Loading /> 
        : 
          (
            <>
                { productos.length > 0  
                    ? 
                        (
                            <>  
                              <div className="container-fluid">
                                <div className="row">
                                  <div className="col-md-12 pb-4">
                                    <form className="form-inline text-center space-between">
                                          <input className="form-control mr-sm-2" 
                                                 type="search" 
                                                 placeholder="Buscar código" 
                                                 aria-label="Search" 
                                                 name="search"
                                                 value={search}
                                                 onChange={handleChangeSearch}
                                          />
                                          <button 
                                                  onClick={handleSearch}
                                                  className="btn btn-outline-success my-2 my-sm-0" 
                                                  type="submit"
                                                  > Search
                                          </button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            
                              <br></br>

                              <table className="table">
                                    <thead className="thead-dark">
                                      <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" className="text-center">Codigo</th>
                                        <th scope="col" className="text-center">CAJA/SECTOR</th>
                                        <th scope="col" className="text-center">Cantidad (O)</th>
                                        <th scope="col" className="text-center">Cantidad (E)</th>
                                        <th scope="col" className="text-center">Diferencia </th>
                                        <th scope="col" className="text-center">Estado </th>
                                        <th scope="col" className="text-center">Usuario </th>
                                        {/* <th scope="col" className="text-center">Img </th> */}
                                        {/* <th scope="col" className="text-center">Descripción</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                          currentData.map( (producto, index) => (
                                              <ProductosGestionList 
                                                key        = {producto._id}
                                                index      = {index} 
                                                {...producto}
                                              />
                                          ))
                                      }
                                    </tbody> 
                              </table>
                              
                              <Paginator
                                    totalRecords={productos.length}
                                    pageLimit={pageLimit}
                                    pageNeighbours={2}
                                    setOffset={setOffset}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                              />
                              <div className="text-center mb-5">
                                  <div row="row">
                                      <div className="col-12">
                                      
                                      <CSVLink 
                                        data={auxList}
                                        headers={headers}
                                        filename={`${name}.csv`}
                                        className="btn btn-primary btn-lg"
                                      >
                                          Descargar 
                                      </CSVLink>
                                      </div>
                                  </div>
                              </div>
                            </>

                        )
                       
                        :   ( 
                              <>
                                <p> No hay registros </p>
                                        <button 
                                            onClick={refreshData}
                                            className="btn btn-outline-success my-2 my-sm-0" 
                                            type="submit"
                                        > Refresh
                                </button>
                              </> 
                              )            
                }        

            </>
        )
    )
    
}
