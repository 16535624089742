import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
  } from 'react-router-dom';

import { LoginScreen } from '../components/auth/LoginScreen';
import { DashboardRoutes } from './DashboardRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { startChecking } from '../actions/auth';
  
import { PublicRoute  }  from '../router/PublicRoute';
import { PrivateRoute }  from '../router/PrivateRoute';


export const AppRouter = () => {

    const dispatch         = useDispatch();
    const { checking, uid} = useSelector(state => state.auth)
    
    //Renovar un token
    useEffect(() => {
        dispatch(startChecking());
    }, [dispatch])

    //Pagina de loading mientras verifica token
    if ( checking ) {
        return (<h1>Loading</h1>)        
    }

    return (
        <Router>
            <div>
                <Switch>
                    <PublicRoute
                        exact 
                        path="/login" 
                        component={ LoginScreen }
                        isAuthenticated= {!!uid}
                    />
                    <PrivateRoute  
                        path="/" 
                        component={ DashboardRoutes } 
                        isAuthenticated= {!!uid}
                    />
                    
                </Switch>
            </div>
        </Router>
    )
}
