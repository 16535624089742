
export const types = {

    //LOGIN Y REGISTER (USUARIOS)
    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authStartRegister: '[auth] Start Register',
    authStartStartTokenRenew: '[auth] Start token renew',
    authLogout: '[auth] Logout',

    //CATEGORIAS
    getCategorias : '[Categorias] Comenzar a cargar categorias',
    tipoCategoriaSetActive: '[Categorias] Seleccionar una categoria',

    // TIPO GESTIÓN
    getTiposGestion : '[Tipos gestión] Comenzar a cargar tipos gestión',
    tipoGestionSetActive: '[Tipos gestión] Seleccionar un tipo gestión',

    //GESTIONES GET
    getStartLoadingGestiones: '[Gestión] Comenzar a cargar gestiones',
    getStartLoadingGestionesSearch: '[Gestión] Comenzar a buscar gestion',
    getGestiones: '[Gestión] cargado gestiones con éxito',
    gestionSetActive: '[gestion] Seleccionar gestión',

    // gestiones INVENTARIO V2
    getGestionesInv: '[Gestión] cargado gestiones de inventario V2 con éxito',

    // getStartProductosGestion: '[gestion] Comenzar a cargar la gestión',

    //GET PRODUCTOS
    startLoading:'[gestion] Comenzar a cargar - sin palabras ',
    startLoadingProducto:'[gestion] Comenzar a cargar productos ',
    startLoadingProductoSearch:'[gestion] Comenzar a buscar productos ',
    getProductosGestion:'[gestion] Seleccionar productos de gestión activa ',

    //File
    porcentajeSet: '[File] cargando porcentaje',
    startLoadingFile: '[File] Comenzar a cargando datos',
    getProductosFileInventario: '[File] cargar archivos de file inventario',
    getProductosFileGuia: '[File] cargar archivos de file guia',


    getProductosFileInventarioV2_Sectores: '[File] cargar archivos de file inventario V2 - SECTORES',


    //GESTIONES POST
    crearNuevaGestion: '[Gestión] Crear una nueva gestión',
    creandoInventario:  '[Gestión] Creando inventario en la base de datos',
    creandoGuia:  '[Gestión] Creando guia en la base de datos',
    
    //GESTION PUT
    limpiarActualizarGestion: '[Gestión] Limpiando reducer de editar gestión',
    gestionStartActualizar: '[Gestión] seleccionar gestión para actualizar',
    actualizarGestion: '[Gestión] actualizar una gestión',

    actualizarGestionEstado: '[Gestión] Actualizar gestión estado',

    //GESTION ELIMINAR
    // limpiarActualizarGestion: '[Gestión] Limpiando reducer de editar gestión',
    // gestionStartElinina: '[Gestión] seleccionar gestión para actualizar',
    eliminarGestion: '[Gestión] Eliminar una gestión y sus referencias',


    //LOGOUT
    logout: '[Gestión] Cerrar sesión',




}