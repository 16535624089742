import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

//Componentes:
// import { Sidebar } from '../components/ui/Sidebar'
import { Navbar } from '../components/ui/Navbar'
import { DashboardScreen } from '../components/DashboardScreen'
import { CreateGestionScreen } from '../components/gestion/CreateGestionScreen'
import { UpdateGestionScreen } from '../components/gestion/UpdateGestionScreen'
import { GestionScreen } from '../components/gestion/GestionScreen'

//styles
import '../components/dashboard.css';
import { RegisterScreen } from '../components/auth/RegisterScreen'
import { useDispatch } from 'react-redux'
import { startLoadingCategorias, startLoadingTipos } from '../actions/gestion'


export const DashboardRoutes = () => {

    const dispatch = useDispatch();
    
    //Cargar categorias y tipos de gestion
    useEffect(() => {

        dispatch(startLoadingTipos());
        dispatch(startLoadingCategorias());

    }, [dispatch])

    return (
        <>
            <div id="page-content-wrapper">
                <Navbar />
                <div className="container-fluid mt-2">
                    <Switch>
                        <Route exact path="/gestiones" component={ DashboardScreen }/>
                        <Route exact path="/gestiones/nuevo" component={ CreateGestionScreen }/> 
                        <Route exact path="/gestiones/editar/:gestionID" component={ UpdateGestionScreen }/> 
                        <Route exact path="/gestiones/:gestionID" component={ GestionScreen }/> 
                        <Route exact path="/registrar" component={ RegisterScreen }/> 
                        
                        <Redirect to='/gestiones' />
            
                    </Switch>
                </div>
            </div>
        </>
    )
}
