import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Paginator from 'react-hooks-paginator';

import { Producto } from './Producto';
import { Loading } from '../ui/Loading';

export const FileProductosList = ({productos }) => {

    const pageLimit = 10;

    const columns   = useSelector( state => state.gestion.columnsFile);    


    //PAGINACION
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data] = useState([]);
    const [currentData, setCurrentData] = useState([]);

    
    useEffect(() => {
        setCurrentData(productos.slice(offset, offset + pageLimit));
    }, [offset, data, productos]);
    
    if (!productos && !columns ) return <Loading />;
    
    return (
        <div>
            {   productos.length > 0 
                        ?  
                        
                        <>
                            <table className="table table-dark">
                                        <thead className="thead-dark">
                                            <tr>
                                                {
                                                    columns.map( (c, index) => (                                    
                                                        <th className="text-uppercase" key={index} scope="col">{c.dataField}</th>
                                                    ))
                                                }
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            currentData.map( (producto, index) => (
                                                <Producto 
                                                    key   = {index}
                                                    {...producto}
                                                />
                                            ))
                                        }
                                        </tbody> 
                            </table>

                            <Paginator
                                totalRecords={productos.length}
                                pageLimit={pageLimit}
                                pageNeighbours={2}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </> 
                        : null }
        </div>
    )
}
