import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../actions/auth';
import { logoutClean} from '../../actions/gestion';
import { Link } from 'react-router-dom';
import { Categorias } from '../categorias/Categorias';

export const Navbar = () => {    
  
    const dispatch   = useDispatch();
    const nombre     = useSelector( state => state.auth.nombre );    
    const categorias = useSelector( state => state.gestion.categorias );

   
    
    useEffect(() => {
  
    }, [categorias, nombre])
    
  
    if (!categorias || !nombre) {
      return null;
    } 

    const handleLogout = () => {
        dispatch( startLogout() );
        dispatch( logoutClean() );
    }

    
   

    return (
        <nav className="navbar navbar-dark bg-dark mb-4 navbar-expand-lg border-bottom">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <Link to="/" className="text-uppercase text-white text-center pr-4">DASHBOARD NICHIMAR</Link>

 
            <div className="collapse navbar-collapse" id="navbarSupportedContent">

              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                {
                  categorias.map( (categoria, index) => (
                    <Categorias 
                      key = { categoria._id }
                      categorias = {categoria}
                      index = {index}
                    />
                  ))  
                } 

                <Link 
                  to="/registrar"
                  className="list-group-item bg-success text-center text-uppercase text-decoration-none text-white pr-5"
                > USUARIOS </Link>
                
                  <button 
                      className="btn btn-outline-danger pl-4"
                      onClick={handleLogout}
                  >
                      <i className="fas fa-sign-out-alt"></i>
                      <span>  Salir | {nombre} </span>
                  </button>
              </ul>
              
            </div>
        </nav>      
    )
}
