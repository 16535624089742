import React, { useEffect } from 'react'

import { useTipoGestion } from '../../hooks/useTipoGestion'
import { useSelector, useDispatch } from 'react-redux';

import { gestionSetActive, startLoadingProducto } from '../../actions/productos';

import { getProductosFileInventario, 
         getProductosFileGuia,
         actualizarGestion } from '../../actions/gestion';

import { Loading } from '../ui/Loading';
import { GestionTableList } from './GestionTableList';
import { useForm } from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';


export const UpdateGestionScreen = ({history}) => {
    

    const dispatch    = useDispatch();
    const {gestionID} = useParams();

    
    //Editar
    const editarGestion   = useSelector( state => state.gestion.editarGestion);
    //Categoria activa
    const categoriaActiva = useSelector( state => state.gestion.categoriaActiva );
    //opciones
    const tipos      = useSelector( state => state.gestion.tipos );
    const tipoActivo = useSelector( state => state.gestion.tipoActivo );
    //Hook
    const [tipo]  = useTipoGestion('', tipoActivo, tipos);
    
    //
    const loading  = useSelector(state => state.gestion.loading );
    const loadingP = useSelector(state => state.producto.loading);


    
    //State form
    const [formValues, guardarGestion] = useForm({
        descripcion:editarGestion.descripcion,
    });

    useEffect(() => {
        dispatch(startLoadingProducto());
        dispatch(gestionSetActive(gestionID));
    }, [dispatch, gestionID])
    
    //Cargar datos
    useEffect(() => {
        const getFile = () => {
             //Validar tipo
             const tipo = tipos.filter(tipo => tipo._id === tipoActivo);
             if (tipo[0].gestion === 'INVENTARIO') {
                 return dispatch(getProductosFileInventario())
             } 
             else if(tipo[0].gestion === 'EMBARQUE') {
                 return dispatch( getProductosFileGuia())
             }          
         }
        getFile();

    }, [dispatch, tipo, tipos, tipoActivo])

    if (!editarGestion) {
        return (<p>Loading .... </p>)
    }

    const gestion = ({
        id: editarGestion._id,
        descripcion: '',
        categoria: '',
        tipo: ''
    })
    
    // guardarGestion(editarGestion.descripcion)


    //RETURN BUTTON
    const handleReturn = () => {
        if (history.length <= 2) {
            history.push('/')
        } else {
            history.goBack();
        }
    }


    const { descripcion } = formValues;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (descripcion.trim() === '') {
            return Swal.fire('Error', 'La gestión es obligatoria', 'error');
        }
        //Inicializar 
        gestion.descripcion = descripcion;
        gestion.categoria = categoriaActiva._id;
        gestion.tipo = tipoActivo;

        
        dispatch(actualizarGestion(gestion))
        history.push('/');
    } 


    return (
        <>
            <div className="row justify-content-center">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">

                        <button 
                            className="btn btn-outline-danger"
                            onClick={handleReturn}
                        > Return </button>
                        <p/>

                        <h2 className="text-center mt-4 mb-4 font-weight-bold">  EDITAR GESTIÓN  </h2>
                        <h4 className="text-center mb-2 text-uppercase text-info"> Categoria: {categoriaActiva.categoria} </h4>
                        <hr/>
                        
                        { (loading || loadingP) && <Loading />} 
                        <form 
                            onSubmit={handleSubmit}
                        >
                            <div className="form-group mt-4">
                                <label> Gestión </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Ingresar gestión"
                                    name="descripcion"
                                    value={descripcion}
                                    onChange={guardarGestion}
                                />
                            </div>
                            <button
                                type="submit"
                                className=" btn
                                            btn-info
                                            font-weight-bold
                                            text-uppercase
                                            d-block w-100"
                            > Actualizar </button>
                        </form>
                        <div className="mt-5">
                        { !loadingP && <GestionTableList />} 

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
