import React from 'react'
import { useDispatch } from 'react-redux';
import { useForm } from '../../hooks/useForm';
import { startRegister } from '../../actions/auth';
import Swal from 'sweetalert2';

export const RegisterScreen = ({history}) => {

    //RETURN BUTTON
    const handleReturn = () => {
        if (history.length <= 2) {
            history.push('/')
        } else {
            history.goBack();
        }
    }
    const dispatch = useDispatch();
    
    const [formLoginValues, handleRegisterInputChange] = useForm({
        nombre:"Sujeto de prueba",
        email:"test@gmail.com",
        password1:"role_default",
        password2:"role_default"
    });

    const { nombre, email, password1, password2 } = formLoginValues;

    //Submit Form
    const handleRegister = ( e ) => {
        e.preventDefault();        

        if (password1.trim() !== password2.trim()) {
            return  Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
        }

        dispatch( startRegister( email, password1, nombre ) );
    }

    return (
        <div className="container login-container">
            <div className="row">
                <div className="col-md-12 login-form-1">
                    <button 
                            className="btn btn-outline-danger"
                            onClick={ handleReturn }
                        > Return </button>
                    <p/>
                    <h3 className="text-uppercase">Registrar un nuevo usuario</h3>
                    <form
                        onSubmit={handleRegister}
                    >
                        <div className="form-group">
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Nombre"
                                name="nombre"
                                value={nombre}
                                onChange={handleRegisterInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Correo"
                                name="email"
                                value={email}
                                onChange={handleRegisterInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Contraseña"
                                name="password1"
                                value={password1}
                                onChange={handleRegisterInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Repetir contraseña"
                                name="password2"
                                value={password2}
                                onChange={handleRegisterInputChange}
                            />
                        </div>
                        <div className="form-group text-center">
                            <input 
                                type="submit"
                                className="btnSubmit"
                                value="REGISTRAR" 
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div> 
    )
}
