import React from 'react';
import ReactDOM from 'react-dom';
import { BodegaApp } from './BodegaApp';


ReactDOM.render(
    <BodegaApp />,
  document.getElementById('root')
);

