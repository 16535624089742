import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { gestionReducer } from './gestionReducer';
import { productosReducer } from './productosReducer';


export const rootReducer = combineReducers({
   auth: authReducer,
   gestion: gestionReducer,
   producto: productosReducer
})

